import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"id":"add-member-container","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-alert-octagon-outline","title":"Error"}},[_c(VCardText,[_c(VAlert,{attrs:{"color":"error","outlined":"","dark":"","show":"","variant":"danger"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_vm._v(" Error Code: ")]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm.errorCode)+" ")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_vm._v(" Error Message: ")]),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[(_vm.errorMap[_vm.errorCode])?_c('span',[_vm._v(" "+_vm._s(_vm.errorMap[_vm.errorCode])+" ")]):_c('span',[_vm._v(" Unhandled error processing request ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }